import React from 'react'
import PropTypes from 'prop-types'
import Input from './Input'
import Text from './Text'
import Gap from './Gap'
import Col from './Col'

const TextField = ({ label, hasError, error, isFooter, width, maxWidth, ErrorComponent, ...props }) => {
  const InputComponent = isFooter ? Input.Dark : Input
  if (!ErrorComponent) ErrorComponent = Text.Error
  return (
    <Col width={width} maxWidth={maxWidth} position="relative">
      <InputComponent hasError={error || hasError} {...props} />
      {(error || hasError) && <ErrorComponent>{error || label}</ErrorComponent> }
    </Col>
  )
}

TextField.defaultProps = {
  hasError: false,
  label: '',
  error: '',
}

TextField.propTypes = {
  hasError: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
}

export default TextField
