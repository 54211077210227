import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from '.'
import { down, up } from '../lib/styles'
import replayImage from '../data/images/usage/replay.png'

const Button = styled.div`
  ${({
    theme: { fonts, colors },
    padding = '19px 24px 19px 24px',
    height = '64px',
    width = '406px',
    responsiveFullWidth,
    bp = 'mobile',
    disabled,
  }) => css`
      /* FONT NENÍ UPLNĚ VYCENTROVANÝ, TAKŽE JE POTŘEBA CENTROVAT PADDINGEM A NE LINE-HEIGHT */
    /* min-width: 406px; */
    min-height: ${height};
    user-select: none;
    /* line-height: ${height}; */
    display: inline-block;
    border-radius: 12px;
    color: ${colors.textWhite};
    font-family: ${fonts.hind};
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    pointer-events: all;
    text-align: center;
    text-transform: uppercase;

    background: rgba(105,105,105,0.34);

    cursor: pointer;
    transition: 0.2s;
    padding: ${padding};
    width: ${width};
    transition: 0.3s;
    max-width: 100%;

    ${disabled
      && css`
        opacity: 0.5;
        pointer-events: none;
      `}

    ${responsiveFullWidth
      && css`
        ${down(bp)} {
          width: 100%;
        }
      `}

    border: solid 2px transparent;

    &:hover {
      color: ${colors.textBlack};
      background: white;
      /* background: rgba(255,255,255,0.8); */
      /* height: 64px; */
      border-color: rgba(105,105,105,0.34);
    }
  `}
`

const ButtonLink = styled(Link)`
  ${({ theme, responsiveFullWidth, bp = 'mobile' }) => css`
    ${responsiveFullWidth
      && css`
        ${down(bp)} {
          width: 100%;
        }
      `}
  `}
`

Button.Link = ({ responsiveFullWidth, buttonProps, children, bp, ...rest }) => {
  const Component = Button

  return (
    <ButtonLink
      noStyle
      responsiveFullWidth={responsiveFullWidth}
      bp={bp}
      {...rest}
      style={{ maxWidth: '100%' }}
    >
      <Component
        {...buttonProps}
        responsiveFullWidth={responsiveFullWidth}
        bp={bp}
      >
        {children}
      </Component>
    </ButtonLink>
  )
}

const RoundedButton = styled(Button)`
  border-radius: 50%;

  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  padding-top: 2px;
`

Button.Watch = props => <RoundedButton {...props}>Watch</RoundedButton>

Button.SubmitForm = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    font-size: 16px;
    border: 1px solid ${colors.textWhite};
    color: ${colors.textWhite};
    font-family: ${fonts.hind};
    background-color: black;
    line-height: 28px;
    padding: 0 15px;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    user-select: none;
    &:hover {
      background-color: white;
      color: ${colors.textBlack};
    }
  `}
`

Button.SubmitInvestForm = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    display: flex;
    font-size: 16px;
    border: 1px solid ${colors.textWhite};
    color: ${colors.textWhite};
    font-family: ${fonts.hind};
    background-color: #3157a6;
    line-height: 28px;
    padding: 2px 15px 0;
    min-height: 40px;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    align-items: center;
    justify-content: center;
    user-select: none;

    color: ${colors.textWhite};
    font-family: ${fonts.hind};
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    pointer-events: all;
    border: none;


    /* background: rgba(105,105,105,0.34); */


    &:hover {
      background-color: white;
      color: ${colors.textBlack};
    }
  `}
`

Button.WriteUs = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    font-size: 22px;
    border: 1px solid black;
    color: ${colors.textBlack};
    font-family: ${fonts.hind};
    padding: 1px 15px 0;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    display: inline-block;
    margin-top: 5px;
    margin-left: 5px;
    user-select: none;
    &:hover {
      background-color: black;
      color: ${colors.textWhite};
    }
  `}
`
const Replay = styled.div`
  cursor: pointer;
  color: #ffffff;
  font-family: Hind;
  font-size: 30px;
  font-weight: 300;
  height: 64px;
  text-transform: uppercase;
  border: 1px solid white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0 30px;
  background: rgba(0, 0, 0, 0.45);
  user-select: none;
  bottom: 0;
  right: unset;
  left: -20px;

  transform: scale(0.5);

  ${up('mobile')} {
    right: 30px;
    left: unset;
    bottom: 30px;

    transform: scale(0.75);
  }
  ${up('tablet')} {
    right: 50px;
    bottom: 50px;

    transform: scale(0.8);
  }
`

Button.Replay = ({ show, ...props }) => show ? (
  <Replay {...props}>
    <img
      src={replayImage}
      alt="replay icon"
      style={{ marginRight: '25px' }}
    />
    Replay
  </Replay>
) : null

Button.Jobs = styled.div`
  ${({ theme: { fonts, colors } }) => css`
    display: inline-block;
    border-radius: 12px;
    background: ${colors.green};
    font-family: ${fonts.hind};
    /* height: 110px; */
    line-height: 80px;
    width: 364px;
    max-width: 100%;

    color: ${colors.textWhite};
    font-family: ${fonts.hind};
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    background: linear-gradient(to right, #50f3ae, #51f895);
    user-select: none;
    &:hover {
      /* background: #2bc8c0; */
    }

    ${up('mobile')} {
      /* height: 80px; */
      line-height: 110px;
    }
  `}
`

export default Button
